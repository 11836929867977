/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' styles.
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Scrollbars
/* ----------------------------------------------------------------------------------------------------- */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2) !important;
  border-radius: 9999px !important;
  border: 3px solid rgba(0,0,0,0);
  background-clip: padding-box;
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Input and TextArea
/* ----------------------------------------------------------------------------------------------------- */
input,
textarea {
    background: transparent;

    /* Placeholder color */
    &::placeholder {
        @apply text-hint;
    }
    &::-moz-placeholder {
        @apply text-hint;
    }
    &::-webkit-input-placeholder {
        @apply text-hint;
    }
    &:-ms-input-placeholder {
        @apply text-hint;
    }
    &:-webkit-autofill {
        transition: background-color 600000s 0s, color 600000s 0s !important;
    }
    &:-webkit-autofill:hover {
        transition: background-color 600000s 0s, color 600000s 0s !important;
    }
    &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s !important;
    }
    &:-webkit-autofill:active {
        transition: background-color 600000s 0s, color 600000s 0s !important;
    }
    [data-autocompleted] {
        background-color: transparent !important;
    }
}
