/* ----------------------------------------------------------------------------------------------------- */
/*  @ Kendo UI overrides
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/* @ Kendo FileSelect
/* ----------------------------------------------------------------------------------------------------- */
.k-upload
{
  background: transparent !important;
  border: none;
  .k-dropzone {
    background: transparent !important;
    font-family: theme('fontFamily.sans');
    padding-left: 0;
    .k-upload-button {
      @apply text-default;
      background: transparent !important;
      border: none;
      border-radius: 9999px !important;
      cursor: pointer;
      font-family: theme('fontFamily.sans');
      height: 40px;
      margin: 0;
      padding: 0 1.5rem !important;
      text-transform: none;
      white-space: nowrap;
      &::before {
        content: unset;
      }
      &::after {
        background-color: rgba(0, 0, 0, 0.1);
        display: block !important;
        // opacity 0 inherited
        transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }
      &:hover {
        &::after {
          opacity: 1 !important;
        }
      }
      input {
        bottom: 0;
        font-size: 0 !important;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
      }
      span {
        z-index: 1;
      }
    }
    .k-dropzone-hint {
      @apply text-hint #{'!important'};
    }
  }
  .k-upload-files {
    .k-file-group {
      @apply dark:text-cyan-50;
    }
    .k-file-name {
      @apply dark:text-cyan-50;
    }
    .k-file-size {
      @apply dark:text-cyan-50;
    }
    .k-upload-status {
      .k-button {
        @apply dark:text-white;
      }
    }
    @apply dark:border-gray-50;
  }
}