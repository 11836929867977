/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

@layer utilities {
  /* ----------------------------------------------------------------------------------------------------- */
  /*  @ Font
  /* ----------------------------------------------------------------------------------------------------- */
  .font-stretch-50 {
    font-stretch: 50%;
  }
  .font-stretch-60 {
    font-stretch: 60%;
  }
  .font-stretch-70 {
    font-stretch: 70%;
  }
  .font-stretch-80 {
    font-stretch: 80%;
  }
  .font-stretch-90 {
    font-stretch: 90%;
  }
}
